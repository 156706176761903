import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { useChangeLanguage } from "remix-i18next";
import { useTranslation } from "react-i18next";
import { useEffect, type CSSProperties } from "react";
import { json } from "@remix-run/node";
import type { LoaderArgs } from "@remix-run/node";
import RecruitingLogo from "./components/recruiting_logo";
import { Footer } from "./components/footer";

import globalStyles from "./styles/global.css";
import typographyStyles from "./components/typography.css";
import footerStyles from "./components/footer.css";
import { Body, SectionHeader } from "./components/typography";
import { isEmbeddedRoute, isInternalRoute } from "~/utils/route_helpers";
import config from "~/config.server";
import { fetchBoardConfiguration } from "~/utils/fetch_board_configuration";
import { BoardConfigurationContext } from "~/contexts/board_configuration_context";
import { ErrorFlash, links as flashLinks } from "~/components/flash";
import { COLOR } from "./types/ui";
import { configuredStyles } from "~/utils/configured_styles";
import { UrlTokenContext } from "~/contexts/url_token_context";
import assetUrl from "~/utils/asset_url.server";
import { buildGoogleFontsLink } from "./utils/google_font_helper";
import { Banner } from "./components/banner";
import bannerStyles from "./components/banner.css";
import { detectLocale } from "./utils/detect_locale";
import { Resizer } from "~/utils/resizer";

export function links() {
  return [
  { rel: "stylesheet", href: globalStyles },
  { rel: "stylesheet", href: footerStyles },
  { rel: "stylesheet", href: typographyStyles },
  { rel: "stylesheet", href: bannerStyles },
  ...flashLinks()];

}

export async function loader({ params, request }: LoaderArgs) {
  const locale = await detectLocale(request);
  const internal = isInternalRoute(request.url);
  const embedded = isEmbeddedRoute(request.url);
  const { searchParams } = new URL(request.url);
  const urlToken =
  (internal ? searchParams.get("token") : params.url_token) || searchParams.get("for") || null;

  let boardConfiguration = null;
  if (typeof urlToken === "string") {
    const { configuration, status } = await fetchBoardConfiguration({ urlToken });

    if (status === 404 || configuration === undefined) {
      throw new Response(null, { status: 404 });
    }

    boardConfiguration = configuration;
  }

  const locationControlProvider = config.get("location_control.provider");

  return json({
    internal,
    locale,
    ENV: {
      ASSET_URL: assetUrl,
      EMAIL_ADDRESS_VALIDATOR_HOST: config.get("email_address_validator_host"),
      JBEN_URL: config.get("domains.jben_url"),
      LOCATION_CONTROL_PROVIDER: locationControlProvider,
      LOCATION_CONTROL_API_KEY:
      locationControlProvider === "pelias" ?
      config.get("location_control.pelias_api_key") :
      config.get("location_control.mapbox_api_key"),
      LOTUS_GIT_COMMIT: config.get("lotus.git_commit"),
      MAX_POSTS_PER_PAGE: config.get("max_posts_per_page"),
      ROLLBAR_ENV: config.get("rollbar.env"),
      ROLLBAR_FE_ENABLED: config.get("rollbar.fe_enabled"),
      ROLLBAR_POST_CLIENT_ITEM_TOKEN: config.get("rollbar.post_client_item_token"),
      ROLLBAR_FILTER_HYDRATION_ERRORS: config.get("rollbar.filter_hydration_errors"),
      GOOGLE_PICKER_APP_ID: config.get("google.picker.app_id"),
      GOOGLE_PICKER_DEVELOPER_KEY: config.get("google.picker.developer_key"),
      GOOGLE_PICKER_CLIENT_ID: config.get("google.picker.client_id"),
      DROPBOX_CHOOSER_API_KEY: config.get("dropbox.chooser_api_key"),
      GOOGLE_RECAPTCHA_INVISIBLE_KEY: config.get("google.recaptcha.invisible_key"),
      GOOGLE_RECAPTCHA_ENDPOINT: config.get("google.recaptcha.endpoint"),
      JOB_SEEKERS_URL: config.get("job_seekers.url")
    },
    boardConfiguration,
    urlToken,
    embedded
  });
}

export function ErrorBoundary() {
  const { t } = useTranslation("common");
  const error = (useRouteError() as Error);

  const ErrorBody = () => {
    if (isRouteErrorResponse(error) && error.status === 404) {
      const message = t("errors.job_board_inactive");
      return (
        <>
          <SectionHeader>{t("errors.page_not_found")}</SectionHeader>
          <div className="job-board-inactive">
            <Body>{message}</Body>
          </div>
          <ErrorFlash message={message} />
        </>);

    }

    return <div>{t("errors.generic")}</div>;
  };

  return (
    <html lang="en">
      <head>
        <title>{t("errors.page_not_found")}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div id="react-portal-mount-point" />
        <main className="main">
          <RecruitingLogo />
          <div className={"error-message font-secondary"}>
            <ErrorBody />
          </div>
        </main>
      </body>
    </html>);

}

export default function App() {
  let { internal, locale, ENV, boardConfiguration, urlToken, embedded } =
  useLoaderData<typeof loader>();

  let { i18n } = useTranslation();

  useChangeLanguage(locale);

  const envScript =
  <script
    dangerouslySetInnerHTML={{
      __html: `window.ENV = ${JSON.stringify(ENV)}`
    }} />;



  const styles = configuredStyles(
    boardConfiguration,
    internal ? COLOR.green : COLOR.blue,
    COLOR.primaryTypography
  );

  const bodyStyles: CSSProperties = {};

  if (!embedded) {
    bodyStyles.minHeight = "100vh";
  }

  const googleFontsLink = buildGoogleFontsLink(boardConfiguration);

  useEffect(() => {
    if (embedded) {
      Resizer.getInstance().initListeners();
      Resizer.getInstance().handleResize();
    }
  }, [embedded]);

  if (internal) {
    return (
      <>
        <Links />
        {googleFontsLink && <link rel="stylesheet" href={googleFontsLink} />}
        <UrlTokenContext.Provider value={urlToken}>
          <BoardConfigurationContext.Provider value={boardConfiguration}>
            <Banner url={boardConfiguration?.banner_url} internal={internal} />
            <div id="react-portal-mount-point" />
            <main className="main font-secondary" style={styles}>
              <Outlet />
              {envScript}
              <Scripts />
            </main>
          </BoardConfigurationContext.Provider>
        </UrlTokenContext.Provider>
      </>);

  }

  return (
    <UrlTokenContext.Provider value={urlToken}>
      <BoardConfigurationContext.Provider value={boardConfiguration}>
        <html lang={locale} dir={i18n.dir()}>
          <head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            {googleFontsLink && <link rel="stylesheet" href={googleFontsLink} />}
            <Meta />
            <Links />
          </head>
          <body style={bodyStyles}>
            {!embedded && <Banner url={boardConfiguration?.banner_url} internal={internal} />}
            <div id="react-portal-mount-point" />
            <main className="main font-secondary" style={styles}>
              <Outlet />
            </main>
            {!embedded && <Footer />}
            <ScrollRestoration />
            {envScript}
            <Scripts />
            <LiveReload />
          </body>
        </html>
      </BoardConfigurationContext.Provider>
    </UrlTokenContext.Provider>);

}